import React, { useState, useCallback, useEffect } from 'react';
import {
  Typography,
  Paper,
  Grid,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Divider,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import EventIcon from '@mui/icons-material/Event';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Cookies from 'js-cookie';
import { useTheme } from '@mui/material/styles';
import { formatInTimeZone } from 'date-fns-tz';
import { parseISO, format, isValid } from 'date-fns'

dayjs.extend(utc);
dayjs.extend(timezone);

function ReservationDisplayComponent({ reservationData, onError, onRefresh }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefreshedPrice, setLastRefreshedPrice] = useState(reservationData?.last_refreshed_price);
  const [lastRefreshedDateTime, setLastRefreshedDateTime] = useState(reservationData?.last_refreshed_datetime);
  const [justRefreshed, setJustRefreshed] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(null);
  const [isRefreshingReservation, setIsRefreshingReservation] = useState(false);

  useEffect(() => {
    if (!reservationData) {
      onError(new Error('No reservation data available'));
      return;
    }
    if (!reservationData.trips) {
      onError(new Error('No trip information available'));
      return;
    }
  }, [reservationData, onError]);

  const formatDateTime = (dateTimeString, localTimeString) => {
    if (!dateTimeString) return 'N/A';
    try {
      // First, try to parse the date directly
      let dateTime = parseISO(dateTimeString);
      
      // If parsing fails, try to handle the custom format
      if (!isValid(dateTime)) {
        const [datePart, timePart, timezonePart] = dateTimeString.split(' ');
        dateTime = parseISO(`${datePart}T${timePart}`);
        
        if (isValid(dateTime)) {
          return `${format(dateTime, 'MMMM d, yyyy')} ${localTimeString || format(dateTime, 'h:mm a')} ${timezonePart || ''}`;
        }
      } else {
        // If the initial parsing succeeded, format without timezone
        return `${format(dateTime, 'MMMM d, yyyy')} ${localTimeString || format(dateTime, 'h:mm a')}`;
      }
      
      // If all parsing attempts fail, throw an error
      throw new Error('Unable to parse date');
    } catch (error) {
      console.error('Error parsing date:', error, 'for dateTimeString:', dateTimeString);
      return 'Invalid Date';
    }
  };

  const formatDate = (dateString) => {
    return dateString ? dayjs(dateString).format('MMMM D, YYYY') : 'N/A';
  };

  const formatCurrency = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '$0.00' : `$${number.toFixed(2)}`;
  };

  const formatToEST = (dateTimeString) => {
    return dateTimeString ? dayjs(dateTimeString).tz('America/New_York').format('MMMM D, YYYY h:mm A z') : 'N/A';
  };

  const renderChip = (label, value) => (
    <Chip 
      label={`${label}: ${value ? 'Yes' : 'No'}`} 
      color={value ? "primary" : "default"} 
      variant={value ? "filled" : "outlined"}
    />
  );

  const refreshPrice = useCallback(async () => {
    setIsRefreshing(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_reservation_price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({ reservation_id: reservationData.reservation_id }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setLastRefreshedPrice(data.price.toString());
      setLastRefreshedDateTime(new Date().toISOString());
      setJustRefreshed(true);
    } catch (error) {
      console.error('Error refreshing price:', error);
      onError(new Error('Failed to refresh price'));
    } finally {
      setIsRefreshing(false);
    }
  }, [reservationData?.reservation_id, onError]);

  const handleRefreshReservation = async () => {
    setIsRefreshingReservation(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_pnr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          user_id: reservationData.user_id,
          reservation_id: reservationData.reservation_id
        }),
      });

      console.log('Response status:', response.status);
      const responseText = await response.text();
      console.log('Response text:', responseText);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        throw new Error('Invalid JSON response from server');
      }

      console.log('Parsed response data:', data);

      if (data && (data.response === "Complete" || data.response === "complete")) {
        console.log('Refresh successful');
        setRefreshStatus('success');
        if (typeof onRefresh === 'function') {
          onRefresh();
        } else {
          console.warn('onRefresh is not a function. Unable to refresh data.');
        }
      } else {
        console.log('Unexpected response from server');
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error refreshing reservation:', error);
      setRefreshStatus('error');
    } finally {
      setIsRefreshingReservation(false);
      setOpenConfirmDialog(false);
    }
  };

  const renderFlightCard = (trip, index) => {
    if (!trip) return null;
    return (
      <Card key={index} sx={{ mb: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Trip {index + 1}: {trip.departure_airport} to {trip.arrival_airport}
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Box display="flex" alignItems="center">
                <FlightTakeoffIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="subtitle1">
                  {trip.departure_airport}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                <EventIcon fontSize="small" sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                {formatDateTime(trip.departure_datetime, trip.departure_datetime_local)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Divider>
                <AirplaneTicketIcon color="action" />
              </Divider>
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography variant="subtitle1">
                  {trip.arrival_airport}
                </Typography>
                <FlightLandIcon color="primary" sx={{ ml: 1 }} />
              </Box>
              <Typography variant="body2" color="textSecondary" align="right">
                <EventIcon fontSize="small" sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                {formatDateTime(trip.arrival_datetime, trip.arrival_datetime_local)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Flight Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stepper orientation="vertical">
              {trip.segments && trip.segments.map((segment, segIndex) => (
                <Step key={segIndex} active={true}>
                  <StepLabel>
                    <Typography variant="subtitle1">
                      Flight {segment.flight_number}: {segment.departure_airport} to {segment.arrival_airport}
                    </Typography>
                  </StepLabel>
                  <Box sx={{ ml: 3, mt: 1, mb: 2 }}>
                    <Typography variant="body2">
                      <strong>Departure:</strong> {formatDateTime(segment.departure_datetime, segment.departure_datetime_local)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Arrival:</strong> {formatDateTime(segment.arrival_datetime, segment.arrival_datetime_local)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Aircraft:</strong> {segment.aircraft}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Cabin:</strong> {segment.cabin_name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Seat:</strong> {segment.seat && segment.seat !== "{NULL}" ? segment.seat : 'Not assigned'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fare Class:</strong> {segment.fare_class?.trim()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fare Code:</strong> {segment.fare_code}
                    </Typography>
                    {segment.flight_status && (
                      <Typography variant="body2">
                        <strong>Flight Status:</strong> {segment.flight_status}
                      </Typography>
                    )}
                    {segment.remarks && (
                      <Typography variant="body2">
                        <strong>Remarks:</strong> {segment.remarks}
                      </Typography>
                    )}
                  </Box>
                </Step>
              ))}
            </Stepper>
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  };

  const renderHistoricalPriceChart = () => {
    if (!reservationData.price_history || reservationData.price_history.length === 0) {
      return (
        <Typography variant="body1" align="center">
          No Historical Pricing Data to Display
        </Typography>
      );
    }
  
    const data = reservationData.price_history.map(entry => ({
      date: new Date(entry.timestamp),
      price: parseFloat(entry.price)
    }));
  
    // Filter out any invalid data points
    const validData = data.filter(point => !isNaN(point.date.getTime()) && !isNaN(point.price));
  
    if (validData.length === 0) {
      return (
        <Typography variant="body1" align="center">
          Can't display historical data
        </Typography>
      );
    }
  
    const minPrice = Math.min(...validData.map(d => d.price));
    const maxPrice = Math.max(...validData.map(d => d.price));
  
    return (
      <Box sx={{ width: '100%', height: 300 }}>
        <LineChart
          dataset={validData}
          xAxis={[{ 
            dataKey: 'date',
            scaleType: 'time',
            valueFormatter: (value) => dayjs(value).format('MMM D, YYYY'),
          }]}
          yAxis={[{ 
            dataKey: 'price',
            scaleType: 'linear',
            valueFormatter: (value) => `$${value.toFixed(2)}`,
            min: Math.floor(minPrice * 0.9),  // Add some padding
            max: Math.ceil(maxPrice * 1.1),   // Add some padding
          }]}
          series={[
            {
              dataKey: 'price',
              area: true,
              color: theme.palette.primary.main,
              areaOpacity: 0.5,
              valueFormatter: (value) => `$${value.toFixed(2)}`,
            },
          ]}
          height={300}
          margin={{ top: 10, bottom: 30, left: 60, right: 10 }}
          slotProps={{
            legend: {
              hidden: true
            }
          }}
          tooltip={{
            trigger: 'axis',
            formatter: (params) => {
              const dataPoint = params[0];
              const date = new Date(dataPoint.axisValue);
              const formattedDate = formatInTimeZone(date, 'America/New_York', 'MMM d, yyyy HH:mm:ss zzz');
              return `${formattedDate}<br/>Price: $${dataPoint.value.toFixed(2)}`;
            }
          }}
        />
      </Box>
    );
  };

  const renderHistoricalPriceTable = () => {
    if (!reservationData.price_history || reservationData.price_history.length === 0) {
      return (
        <Typography variant="body1" align="center">
          No Historical Pricing Data to Display
        </Typography>
      );
    }

    return (
      <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
        <Table stickyHeader aria-label="historical price table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservationData.price_history.map((row, index) => (
              <TableRow 
                key={index}
                sx={{ 
                  '&:nth-of-type(odd)': { backgroundColor: 'action.hover' },
                  '&:hover': { backgroundColor: 'action.selected' },
                  ...(hoveredRow === index && { backgroundColor: 'primary.light' }),
                }}
                onMouseEnter={() => setHoveredRow(index)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                <TableCell component="th" scope="row">
                  {formatDateTime(row.timestamp)}
                </TableCell>
                <TableCell align="right">{formatCurrency(row.price)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (!reservationData) {
    return <Typography>No reservation data available</Typography>;
  }

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 2, 
        mb: 2, 
        border: '2px solid #1976d2',
        transition: 'box-shadow 0.3s',
        boxShadow: isHovered ? '0 0 10px 0 #1976d2' : 'none',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" align="center" gutterBottom>
          <strong>Reservation ID: {reservationData.reservation_id}</strong>
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => navigate(`/record-refaring?reservation_id=${reservationData.reservation_id}`)}
          >
            Record Refaring
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="medium"
            startIcon={<RefreshIcon />}
            onClick={() => setOpenConfirmDialog(true)}
            disabled={isRefreshingReservation}
          >
            {isRefreshingReservation ? <CircularProgress size={24} /> : 'Refresh Reservation'}
          </Button>
        </Box>
      </Box>
      <Typography variant="h5" align="center" gutterBottom>
        <strong>PNR: {reservationData.pnr} | User ID: {reservationData.user_id}</strong>
        <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
          <Tooltip 
            title={
              <Typography variant="h6">{`${reservationData.user_full_name || `${reservationData.first_name} ${reservationData.last_name}`}`}</Typography>
            } 
            arrow
            placement="top"
          >
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            size="small"
            onClick={() => navigate(`/users?user_id=${reservationData.user_id}`)}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center', mb: 2 }}>
        {renderChip('Active', reservationData.is_active)}
        {renderChip('Archived', reservationData.is_archived)}
        {renderChip('Auto Rebook Enabled', reservationData.is_auto_rebook_enabled)}
        {renderChip('Tracked', reservationData.is_tracked)}
        {renderChip('Upcoming', reservationData.is_upcoming)}
        {renderChip('Visible', reservationData.is_visible)}
        {renderChip('Award', reservationData.is_award)}
        {renderChip('Basic Economy', reservationData.is_basic_economy)}
        {renderChip('Elite Upgrade', reservationData.is_elite_upgrade)}
        {renderChip('Refundable', reservationData.is_refundable)}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography><strong>Airline:</strong> {reservationData.airline?.toUpperCase()}</Typography>
          <Typography><strong>Total Payment:</strong> {formatCurrency(reservationData.payment_amount)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography><strong>Ticket Number:</strong> {reservationData.ticket_number}</Typography>
          <Typography><strong>Issue Date:</strong> {formatDateTime(reservationData.ticket_issue_date)}</Typography>
        </Grid>
      </Grid>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Flight Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {reservationData.trips && reservationData.trips.map((trip, index) => renderFlightCard(trip, index))}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Passenger Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {(reservationData.passengers || reservationData.trips?.[0]?.passengers || []).map((passenger, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{passenger.first_name} {passenger.last_name}</Typography>
                    <Typography variant="body2"><strong>Passenger ID:</strong> {passenger.passenger_id}</Typography>
                    <Typography variant="body2"><strong>Email:</strong> {passenger.email}</Typography>
                    <Typography variant="body2"><strong>Phone:</strong> {passenger.phone}</Typography>
                    {passenger.loyalty_info && (
                      <>
                        <Typography variant="body2"><strong>Loyalty Number:</strong> {passenger.loyalty_info.loyalty_number}</Typography>
                        <Typography variant="body2"><strong>Loyalty Status:</strong> {passenger.loyalty_info.status}</Typography>
                      </>
                    )}
                    <Typography variant="body2"><strong>Status:</strong> {passenger.status}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Pricing Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h4" fontWeight="bold">
                {formatCurrency(reservationData.payment_amount)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Total Original Payment Amount
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Ticket Issue Date: {formatDate(reservationData.ticket_issue_date)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h4" fontWeight="bold">
                {formatCurrency(reservationData.total_saved_amount)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Total Saved on this Reservation
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h4" fontWeight="bold">
                {formatCurrency(reservationData.current_effective_price)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Current Effective Price
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Fare Paid, Adjusted for Lowest Fare Guarantee
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1, opacity: isRefreshing ? 0.5 : 1 }}>
                  <Typography variant="h4" fontWeight="bold">
                    {formatCurrency(lastRefreshedPrice)}
                  </Typography>
                </Box>
                <IconButton 
                  onClick={refreshPrice} 
                  disabled={isRefreshing}
                  sx={{ ml: 1 }}
                >
                  {isRefreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
                </IconButton>
              </Box>
              <Typography variant="body2" color="textSecondary">
                Last Price Seen
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {justRefreshed 
                  ? `Price Found Just Now (${formatToEST(lastRefreshedDateTime)})`
                  : formatToEST(lastRefreshedDateTime)}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>Historical Prices</Typography>
            {renderHistoricalPriceChart()}
            <Box mt={2}>
              <Typography variant="h6" gutterBottom>Price History Table</Typography>
              {renderHistoricalPriceTable()}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Reservation Refresh"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. Are you sure you want to refresh reservation {reservationData.reservation_id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleRefreshReservation} 
            color="primary" 
            autoFocus
            disabled={isRefreshingReservation}
          >
            {isRefreshingReservation ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      {refreshStatus && (
        <Box sx={{ position: 'fixed', top: 24, right: 24, zIndex: 9999 }}>
          <Alert 
            severity={refreshStatus === 'success' ? 'success' : 'error'}
            onClose={() => setRefreshStatus(null)}
          >
            {refreshStatus === 'success' 
              ? 'Reservation refreshed successfully!' 
              : 'Failed to refresh reservation. Please try again.'}
          </Alert>
        </Box>
      )}
    </Paper>
  );
}

export default ReservationDisplayComponent;