// /Users/sam/Desktop/autopilot-admin-app/src/components/ProtectedRoute.js

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useTokenVerification from '../hooks/useTokenVerification';
import { CircularProgress, Box } from '@mui/material';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isVerified, isLoading } = useTokenVerification();

  // Don't run verification on login page
  if (location.pathname === '/login') {
    return children;
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return isVerified ? children : <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute;