// /Users/sam/Desktop/autopilot-admin-app/src/components/PassengerLookup.js

import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { getToken } from '../utils/auth';  // Import the getToken function
import { useLocation } from 'react-router-dom';

const searchFields = [
  'passenger_id',
  'user_id',
  'first_name',
  'last_name',
  'email',
  'phone',
  'ktn',
  'clear',
  'date_of_birth',
];

function formatDateTime(dateTimeString) {
  const date = parseISO(dateTimeString);
  return formatInTimeZone(date, 'America/New_York', "MMM dd, yyyy hh:mm:ss a 'ET'");
}

function Row({ row }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.passenger_id}</TableCell>
        <TableCell component="th" scope="row">
          {row.first_name} {row.last_name}
        </TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">{row.phone}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, backgroundColor: theme.palette.grey[100], borderRadius: 2, p: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Passenger Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Card elevation={3}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Passenger Info
                      </Typography>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">Passenger ID</TableCell>
                            <TableCell>{row.passenger_id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">User ID</TableCell>
                            <TableCell>{row.user_id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Created At</TableCell>
                            <TableCell>{formatDateTime(row.created_at)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Home Airport</TableCell>
                            <TableCell>{row.home_airport}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Preferred Airline</TableCell>
                            <TableCell>{row.preferred_airline}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Seat Preference</TableCell>
                            <TableCell>{row.seat_preference}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card elevation={3}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Trusted Traveler
                      </Typography>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">KTN</TableCell>
                            <TableCell>{row.ktn || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Canadian Travel Number</TableCell>
                            <TableCell>{row.canadian_travel_number || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Clear Plus Subscription</TableCell>
                            <TableCell>{row.clear ? 'Yes' : 'No'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Redress Number</TableCell>
                            <TableCell>{row.redress_number || 'N/A'}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card elevation={3}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Loyalty Information
                      </Typography>
                      {row.loyalty_programs.map((program, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography variant="subtitle2">{program.program_name}</Typography>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row">Loyalty Number</TableCell>
                                <TableCell>{program.loyalty_number}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">Airline Status</TableCell>
                                <TableCell>{program.airline_status}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">Alliance Status</TableCell>
                                <TableCell>{program.alliance_status || 'N/A'}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row">Last Updated</TableCell>
                                <TableCell>{formatDateTime(program.last_updated)}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function PassengerLookup() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('user_id');
  const [passengers, setPassengers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  const handleSearch = useCallback(async () => {
    setLoading(true);
    setError(null);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());

    const raw = JSON.stringify({
      [searchField]: searchTerm
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://dev-api.withautopilot.com/admin_portal/lookup_passengers", requestOptions);
      const result = await response.json();
      
      if (Array.isArray(result)) {
        setPassengers(result);
      } else {
        console.error('API response is not an array:', result);
        setPassengers([]);
        setError('Unexpected API response format');
      }
    } catch (error) {
      console.error('Error fetching passengers:', error);
      setPassengers([]);
      setError('Error fetching passenger data');
    } finally {
      setLoading(false);
    }
  }, [searchField, searchTerm]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('user_id');
    if (userId) {
      setSearchField('user_id');
      setSearchTerm(userId);
      handleSearch();
    }
  }, [location.search, handleSearch]);

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          select
          label="Search Field"
          value={searchField}
          onChange={(e) => setSearchField(e.target.value)}
          SelectProps={{
            native: true,
          }}
        >
          {searchFields.map((field) => (
            <option key={field} value={field}>
              {field}
            </option>
          ))}
        </TextField>
        <TextField
          label="Search Term"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <Button 
          variant="contained" 
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? 'Searching...' : 'Search'}
        </Button>
      </Box>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Passenger ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(passengers) && passengers.length > 0 ? (
              passengers.map((passenger) => (
                <Row key={passenger.passenger_id} row={passenger} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {loading ? 'Loading...' : 'No passengers found'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PassengerLookup;