import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  InputAdornment,
  Modal,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getToken } from '../utils/auth';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import ReservationDisplayComponent from './ReservationDisplayComponent';

dayjs.extend(utc);
dayjs.extend(timezone);

function RecordRefaring() {
  const [reservationId, setReservationId] = useState('');
  const [pnr, setPnr] = useState('');
  const [reservationData, setReservationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPassenger, setSelectedPassenger] = useState('');
  const [voucherNumber, setVoucherNumber] = useState('');
  const [savingsBlocks, setSavingsBlocks] = useState([]);
  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [chargeAmount, setChargeAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [doNotBill, setDoNotBill] = useState(false);
  const [doNotBillReason, setDoNotBillReason] = useState('');
  const [doNotSendEmail, setDoNotSendEmail] = useState(false);
  const [billingInfo, setBillingInfo] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loadingCredit, setLoadingCredit] = useState(false);
  const [activeBlockIndex, setActiveBlockIndex] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleLookup = useCallback(async (id = reservationId, lookupPnr = pnr) => {
    setLoading(true);
    setError(null);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());

    let raw;
    let url;

    if (id) {
      raw = JSON.stringify({ "reservation_id": parseInt(id) });
      url = "https://dev-api.withautopilot.com/admin_portal/find_reservation_for_refare";
    } else if (lookupPnr) {
      raw = JSON.stringify({ "pnr": lookupPnr });
      url = "https://dev-api.withautopilot.com/admin_portal/find_reservation_by_pnr";
    } else {
      setError('Please enter either a Reservation ID or PNR');
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      setReservationData(result);
      if (result.reservation_id) {
        setReservationId(result.reservation_id.toString());
      }
      if (result.pnr) {
        setPnr(result.pnr);
      }
    } catch (error) {
      console.error('Error fetching reservation data:', error);
      setError('Failed to fetch reservation data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [reservationId, pnr]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reservationIdFromQuery = queryParams.get('reservation_id');
    if (reservationIdFromQuery) {
      setReservationId(reservationIdFromQuery);
      handleLookup(reservationIdFromQuery);
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate, handleLookup]);

  useEffect(() => {
    if (reservationData) {
      if (reservationData.billing_info && reservationData.billing_info !== "No billing info available") {
        setBillingInfo(reservationData.billing_info);
        setPaymentMethod(`${reservationData.billing_info.brand} **** ${reservationData.billing_info.last4}`);
      }
      if (reservationData.referral_code && reservationData.referral_code.toLowerCase() === 'zsgsph') {
        setChargeAmount('0.00');
        setDoNotBill(true);
        setDoNotBillReason("Friend's and Family");
      }
      setSavingsBlocks([]);
    }
  }, [reservationData]);

  useEffect(() => {
    const totalSavings = savingsBlocks.reduce((sum, block) => sum + parseFloat(block.amountSaved || 0), 0);
    const calculatedCharge = (totalSavings * 0.25).toFixed(2);
    setChargeAmount(calculatedCharge);
  }, [savingsBlocks]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLookup();
    }
  };

  const getAirlineCode = (airlineName) => {
    const airlineMap = {
      'american': 'aa',
      'delta': 'dl',
      'united': 'ua'
    };
    return airlineMap[airlineName.toLowerCase()] || '';
  };

  const handleCreditLookup = useCallback(async () => {
    if (!selectedPassenger || !voucherNumber) {
      setError('Please select a passenger and enter a voucher number.');
      return;
    }
  
    const passenger = reservationData.passengers.find(p => p.passenger_id === selectedPassenger);
    if (!passenger) {
      setError('Selected passenger not found.');
      return;
    }
  
    setLoadingCredit(true);
    setError(null);
  
    const airlineCode = getAirlineCode(reservationData.airline);
    if (!airlineCode) {
      setError('Invalid or unsupported airline.');
      setLoadingCredit(false);
      return;
    }
  
    const url = `https://dev-api.withautopilot.com/dev/find_credit_info?airline=${airlineCode}&credit_number=${voucherNumber}&first_name=${passenger.first_name}&last_name=${passenger.last_name}`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Key': 'ZSGSPH',
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      const creditInfo = Array.isArray(result) ? result[0] : result;
      
      const expirationDate = creditInfo.expiration_date 
        ? dayjs(creditInfo.expiration_date)
        : dayjs().add(366, 'day');
  
      setSavingsBlocks(prev => [...prev, {
        passengerId: selectedPassenger,
        passengerName: `${passenger.first_name} ${passenger.last_name}`,
        voucherNumber,
        amountSaved: creditInfo.total_amount,
        expirationDate: expirationDate,
        refundType: 'Voucher'
      }]);
      setActiveBlockIndex(savingsBlocks.length);
      setSelectedPassenger('');
      setVoucherNumber('');
  
    } catch (error) {
      console.error('Error fetching credit info:', error);
      setError('Failed to fetch credit info. Please try again.');
    } finally {
      setLoadingCredit(false);
    }
  }, [selectedPassenger, voucherNumber, reservationData, savingsBlocks.length]);

  const handleManualEntry = () => {
    if (!selectedPassenger) {
      setError('Please select a passenger.');
      return;
    }

    const passenger = reservationData.passengers.find(p => p.passenger_id === selectedPassenger);
    if (!passenger) {
      setError('Selected passenger not found.');
      return;
    }

    setSavingsBlocks(prev => [...prev, {
      passengerId: selectedPassenger,
      passengerName: `${passenger.first_name} ${passenger.last_name}`,
      voucherNumber: voucherNumber,
      amountSaved: '',
      expirationDate: dayjs().add(366, 'day'),
      refundType: 'Voucher'
    }]);
    setActiveBlockIndex(savingsBlocks.length);
    setSelectedPassenger('');
    setVoucherNumber('');
  };

  const handleRemoveSavingsBlock = (index) => {
    setSavingsBlocks(prev => prev.filter((_, i) => i !== index));
    setActiveBlockIndex(null);
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setLoading(true);
    const transactionData = {
      reservation_id: reservationId,
      passenger_refaring: savingsBlocks.map(block => ({
        passenger_id: block.passengerId,
        amount_saved: block.amountSaved,
        voucher_number: block.voucherNumber,
        voucher_expiration: block.expirationDate ? block.expirationDate.toISOString() : null,
        refund_type: block.refundType,
      })),
      charge_amount: chargeAmount,
      payment_method: paymentMethod,
      do_not_bill_this_event: doNotBill,
      do_not_bill_reason: doNotBillReason,
      do_not_send_customer_email: doNotSendEmail,
      stripe_customer_id: reservationData?.stripe_customer_id,
      billing_amount: chargeAmount,
      setup_intent_id: billingInfo?.setup_intent_id,
      card_info: billingInfo ? {
        brand: billingInfo.brand,
        last4: billingInfo.last4,
        exp_month: billingInfo.exp_month,
        exp_year: billingInfo.exp_year,
        postal_code: billingInfo.postal_code,
      } : null,
    };
    console.log(JSON.stringify(transactionData));

    try {
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/record_refare', {
        method: 'POST',
        headers: {
          'token': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('API Response:', result);
      
      setSnackbarMessage(`Successfully recorded refaring for reservation ${reservationId}`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      setReservationData(null);
      setReservationId('');
      setSavingsBlocks([]);
      setChargeAmount('');
      setPaymentMethod('');
      setDoNotBill(false);
      setDoNotBillReason('');
      setDoNotSendEmail(false);
      setShowBillingInfo(false);
    } catch (error) {
      console.error('Error recording refare:', error);
      setSnackbarMessage('Failed to record refare. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  }, [reservationId, savingsBlocks, chargeAmount, paymentMethod, doNotBill, doNotBillReason, doNotSendEmail, reservationData, billingInfo]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleChargeAmountChange = (e) => {
    setChargeAmount(e.target.value);
  };

  const handleChargeAmountBlur = () => {
    if (chargeAmount === '') {
      setChargeAmount('0.00');
    } else {
      setChargeAmount(parseFloat(chargeAmount).toFixed(2));
    }
  };

  const canAddMorePassengers = reservationData && reservationData.passengers && savingsBlocks.length < reservationData.passengers.length;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>Record Refaring</Typography>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Reservation ID"
              value={reservationId}
              onChange={(e) => setReservationId(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="PNR"
              value={pnr}
              onChange={(e) => setPnr(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              onClick={() => handleLookup()}
              disabled={loading || (!reservationId && !pnr)}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : 'Lookup'}
            </Button>
          </Grid>
        </Grid>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>

      {reservationData && (
        <ReservationDisplayComponent reservationData={reservationData} />
      )}

      {canAddMorePassengers && (
        <Paper elevation={3} sx={{ p: 2, mb: 2, bgcolor: '#e8f5e9' }}>
          <Typography variant="h6" gutterBottom>Record Savings</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Passenger</InputLabel>
                <Select
                  value={selectedPassenger}
                  onChange={(e) => setSelectedPassenger(e.target.value)}
                  label="Passenger"
                >
                  {reservationData.passengers &&
                    reservationData.passengers
                      .filter(passenger => !savingsBlocks.some(block => block.passengerId === passenger.passenger_id))
                      .map((passenger) => (
                        <MenuItem key={passenger.passenger_id} value={passenger.passenger_id}>
                          {`${passenger.first_name} ${passenger.last_name}`}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Voucher Number"
                value={voucherNumber}
                onChange={(e) => setVoucherNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                onClick={handleCreditLookup}
                disabled={loadingCredit || !selectedPassenger || !voucherNumber}
                fullWidth
              >
                {loadingCredit ? <CircularProgress size={24} /> : 'Lookup Credit Info'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                onClick={handleManualEntry}
                disabled={!selectedPassenger}
                fullWidth
              >
                Enter Credit Info Manually
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {savingsBlocks.map((block, index) => (
        <Paper 
          key={index} 
          elevation={3} 
          sx={{ 
            p: 2, 
            mb: 2, 
            position: 'relative', 
            bgcolor: '#e3f2fd',
            boxShadow: activeBlockIndex === index ? '0 0 0 2px #1976d2' : 'none',
          }}
        >
          <IconButton
            onClick={() => handleRemoveSavingsBlock(index)}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>Savings for {block.passengerName}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Voucher Number"
                value={block.voucherNumber}
                onChange={(e) => {
                  const newBlocks = [...savingsBlocks];
                  newBlocks[index].voucherNumber = e.target.value;
                  setSavingsBlocks(newBlocks);
                }}
                required={block.refundType === 'Voucher'}
                error={block.refundType === 'Voucher' && !block.voucherNumber}
                helperText={block.refundType === 'Voucher' && !block.voucherNumber ? 'Voucher number is required' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Amount Saved"
                value={block.amountSaved}
                onChange={(e) => {
                  const newBlocks = [...savingsBlocks];
                  newBlocks[index].amountSaved = e.target.value;
                  setSavingsBlocks(newBlocks);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                required
                error={!block.amountSaved}
                helperText={!block.amountSaved ? 'Amount saved is required' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Voucher Expiration"
                value={block.expirationDate}
                onChange={(newValue) => {
                  const newBlocks = [...savingsBlocks];
                  newBlocks[index].expirationDate = newValue;
                  setSavingsBlocks(newBlocks);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Refund Type</InputLabel>
                <Select
                  value={block.refundType}
                  onChange={(e) => {
                    const newBlocks = [...savingsBlocks];
                    newBlocks[index].refundType = e.target.value;
                    setSavingsBlocks(newBlocks);
                  }}
                  label="Refund Type"
                >
                  <MenuItem value="Voucher">Voucher</MenuItem>
                  <MenuItem value="Cash">Cash</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      ))}

      {reservationData && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowBillingInfo(true)}
          >
            Review Billing Info
          </Button>
        </Box>
      )}

      <Modal
        open={showBillingInfo}
        onClose={() => setShowBillingInfo(false)}
        aria-labelledby="billing-modal-title"
        aria-describedby="billing-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="billing-modal-title" variant="h6" component="h2">
            Billing Information
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Charge Amount"
                value={chargeAmount}
                onChange={handleChargeAmountChange}
                onBlur={handleChargeAmountBlur}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                disabled={doNotBill}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Payment Method</InputLabel>
                <Select
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  label="Payment Method"
                  disabled={doNotBill}
                >
                  {billingInfo && (
                    <MenuItem value={`${billingInfo.brand} **** ${billingInfo.last4}`}>
                      {billingInfo.brand} **** {billingInfo.last4} (exp. {billingInfo.exp_month}/{billingInfo.exp_year})
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doNotBill}
                    onChange={(e) => setDoNotBill(e.target.checked)}
                  />
                }
                label="Do not bill this event"
              />
            </Grid>
            {doNotBill && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Reason for not billing"
                  value={doNotBillReason}
                  onChange={(e) => setDoNotBillReason(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doNotSendEmail}
                    onChange={(e) => setDoNotSendEmail(e.target.checked)}
                  />
                }
                label="Do Not Send Customer a Confirmation Email"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Complete Refaring Transaction'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
  
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default RecordRefaring;