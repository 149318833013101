import React, { useState } from 'react';
import { 
  TextField, Button, MenuItem, FormControl, InputLabel, Select, Box, Typography, 
  Paper, Grid, Accordion, AccordionSummary, AccordionDetails, Chip, CircularProgress,
  Dialog, DialogTitle, DialogContent, Link, Alert
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import Cookies from 'js-cookie';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const PnrLookup = () => {
  const [formData, setFormData] = useState({
    airline: '',
    firstName: '',
    lastName: '',
    pnr: '',
    format: 'ra'
  });
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openUserIdDialog, setOpenUserIdDialog] = useState(false);
  const [userId, setUserId] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [addPnrLoading, setAddPnrLoading] = useState(false);
  const [addPnrSuccess, setAddPnrSuccess] = useState(false);
  const [addPnrError, setAddPnrError] = useState(null);

  const getToken = () => {
    return Cookies.get('token');
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);

    const myHeaders = new Headers();
    myHeaders.append("Key", "ZSGSPH");
    
    const token = getToken();
    if (token) {
      myHeaders.append("token", token);
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
      const response = await fetch(`https://dev-api.withautopilot.com/dev/pull_pnr?airline=${formData.airline}&first_name=${formData.firstName}&last_name=${formData.lastName}&pnr=${formData.pnr}&fmt=${formData.format}`, requestOptions);
      const data = await response.text();
      setResult(formData.format === 'ra' ? JSON.parse(data) : data);
    } catch (error) {
      setError('An error occurred while fetching the data. Please try again.');
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPnrToUser = () => {
    setOpenUserIdDialog(true);
    setUserInfo(null);
    setAddPnrSuccess(false);
    setAddPnrError(null);
  };

  const handleUserIdLookup = async () => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("accept-language", "en-US,en;q=0.9");
    myHeaders.append("content-type", "application/json");
    myHeaders.append("origin", "http://localhost:3000");
    myHeaders.append("priority", "u=1, i");
    myHeaders.append("referer", "http://localhost:3000/");
    myHeaders.append("sec-ch-ua", "\"Not)A;Brand\";v=\"99\", \"Google Chrome\";v=\"127\", \"Chromium\";v=\"127\"");
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
    myHeaders.append("sec-fetch-dest", "empty");
    myHeaders.append("sec-fetch-mode", "cors");
    myHeaders.append("sec-fetch-site", "cross-site");
    
    const token = getToken();
    if (token) {
      myHeaders.append("token", token);
    }

    myHeaders.append("user-agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36");

    const raw = JSON.stringify({
      "user_id": userId
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://dev-api.withautopilot.com/admin_portal/get_users", requestOptions);
      const result = await response.json();
      if (result && result.length > 0) {
        setUserInfo(result[0]);
      } else {
        setError('User not found');
      }
    } catch (error) {
      console.error('error', error);
      setError('An error occurred while looking up the user');
    }
  };

  const handleConfirmAddPnr = async () => {
    setAddPnrLoading(true);
    setAddPnrSuccess(false);
    setAddPnrError(null);

    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("content-type", "application/json");
    
    const token = getToken();
    if (token) {
      myHeaders.append("token", token);
    }

    const raw = JSON.stringify({
      "user_id": userInfo.user_id,
      "first_name": formData.firstName,  // Use the first name from user input
      "last_name": formData.lastName,    // Use the last name from user input
      "pnr": formData.pnr,
      "airline": formData.airline
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://dev-api.withautopilot.com/admin_portal/add_pnr_to_user", requestOptions);
      const result = await response.text();
      if (result === "Complete") {
        setAddPnrSuccess(true);
        setTimeout(() => {
          setOpenUserIdDialog(false);
          setAddPnrSuccess(false);
        }, 3000);
      } else {
        setAddPnrError('An unexpected error occurred');
      }
    } catch (error) {
      console.error('error', error);
      setAddPnrError('An error occurred while adding the PNR to the user');
    } finally {
      setAddPnrLoading(false);
    }
  };

  const renderPassenger = (passenger, index) => (
    <StyledAccordion key={`passenger-${index}`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{passenger.first_name} {passenger.last_name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Passenger Type: {passenger.passenger_type}</Typography>
            <Typography variant="subtitle2">Loyalty: {passenger.loyalty_program} - {passenger.loyalty_number}</Typography>
            <Typography variant="subtitle2">Airline Status: {passenger.airline_status || 'N/A'}</Typography>
            <Typography variant="subtitle2">Alliance Status: {passenger.alliance_status || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Email: {passenger.emails || 'N/A'}</Typography>
            <Typography variant="subtitle2">Phone: {passenger.phone || 'N/A'}</Typography>
            <Typography variant="subtitle2">KTN: {passenger.ktn || 'N/A'}</Typography>
            <Typography variant="subtitle2">Redress Number: {passenger.redress_number || 'N/A'}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );

  const renderSegment = (segment, index) => (
    <StyledAccordion key={`segment-${index}`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{segment.departure_airport} to {segment.arrival_airport} - {segment.flight_number}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Departure: {new Date(segment.departure_datetime).toLocaleString()}</Typography>
            <Typography variant="subtitle2">Arrival: {new Date(segment.arrival_datetime).toLocaleString()}</Typography>
            <Typography variant="subtitle2">Aircraft: {segment.aircraft}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Cabin: {segment.cabin_name}</Typography>
            <Typography variant="subtitle2">Fare Class: {segment.fare_class}</Typography>
            <Typography variant="subtitle2">Seat: {segment.seat || 'Not assigned'}</Typography>
            <Typography variant="subtitle2">Status: {segment.flight_status || 'N/A'}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );

  const renderFormattedResult = () => {
    if (!result) return null;
    return (
      <Box>
        <Typography variant="h5" gutterBottom>PNR: {result.PNR}</Typography>
        <Typography variant="h6" gutterBottom>Airline: {result.airline.toUpperCase()}</Typography>
        
        <Typography variant="h6" gutterBottom>Passengers:</Typography>
        {result.passengers.map((passenger, index) => renderPassenger(passenger, index))}
        
        <Typography variant="h6" gutterBottom>Itinerary:</Typography>
        {result.slices.map((slice, sliceIndex) => (
          <StyledAccordion key={`slice-${sliceIndex}`}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Trip {sliceIndex + 1}: {slice.departure_airport} to {slice.arrival_airport}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1">Departure: {new Date(slice.departure_datetime).toLocaleString()}</Typography>
              <Typography variant="subtitle1">Arrival: {new Date(slice.arrival_datetime).toLocaleString()}</Typography>
              <Typography variant="subtitle2" gutterBottom>Segments:</Typography>
              {slice.segments.map((segment, segmentIndex) => renderSegment(segment, segmentIndex))}
            </AccordionDetails>
          </StyledAccordion>
        ))}
        
        <Typography variant="h6" gutterBottom>Payment Information:</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>Base Fare: ${result.payment_info.base_fare}</Typography>
            <Typography>Tax Total: ${result.payment_info.tax_total}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Total Amount: ${result.payment_info.payment_amount}</Typography>
            <Typography>Refundable: {result.payment_info.refundable ? 'Yes' : 'No'}</Typography>
          </Grid>
        </Grid>
        
        <Typography variant="h6" gutterBottom>Ticket Information:</Typography>
        {result.ticket_info.map((ticket, index) => (
          <StyledChip 
            key={`ticket-${index}`}
            label={`${ticket.ticket_number} (Issued: ${new Date(ticket.ticket_issue_date).toLocaleDateString()})`}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Lookup PNR with Airline</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel>Airline</InputLabel>
              <Select
                name="airline"
                value={formData.airline}
                onChange={handleChange}
                required
              >
                <MenuItem value="aa">American</MenuItem>
                <MenuItem value="dl">Delta</MenuItem>
                <MenuItem value="ua">United</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="PNR"
              name="pnr"
              value={formData.pnr}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel>Format</InputLabel>
              <Select
                name="format"
                value={formData.format}
                onChange={handleChange}
                required
              >
                <MenuItem value="ra">Formatted</MenuItem>
                <MenuItem value="raw">Unformatted</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Search'}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error && (
        <Typography color="error" mt={2}>{error}</Typography>
      )}
      {result && (
        <StyledPaper>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddPnrToUser}
            style={{ marginBottom: '20px' }}
          >
            Add PNR to User
          </Button>
          <Typography variant="h5" gutterBottom>Result:</Typography>
          {formData.format === 'ra' ? renderFormattedResult() : (
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {JSON.stringify(result, null, 2)}
              </pre>
          )}
        </StyledPaper>
      )}
      <Dialog open={openUserIdDialog} onClose={() => setOpenUserIdDialog(false)}>
        <DialogTitle>Enter User ID</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              autoFocus
              margin="dense"
              id="userId"
              label="User ID"
              type="text"
              fullWidth
              variant="outlined"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleUserIdLookup}
              style={{ marginLeft: '10px', height: '56px' }}
            >
              Lookup
            </Button>
          </Box>
          <Link
            component="button"
            variant="body2"
            onClick={() => window.open('/users', '_blank')}
          >
            I don't know the User ID
          </Link>
          {userInfo && (
            <Box mt={2}>
              <Typography variant="body1">
                Are you sure you want to add {formData.pnr} on {formData.airline.toUpperCase()} to {formData.firstName} {formData.lastName} (User ID: {userInfo.user_id})?
              </Typography>
              <Box mt={2}>
                <Button onClick={() => setOpenUserIdDialog(false)} style={{ marginRight: '10px' }}>Cancel</Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleConfirmAddPnr}
                  disabled={addPnrLoading}
                >
                  {addPnrLoading ? <CircularProgress size={24} /> : 'Add PNR'}
                </Button>
              </Box>
            </Box>
          )}
          {addPnrSuccess && (
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ marginTop: '20px' }}>
              PNR successfully added to the user.
            </Alert>
          )}
          {addPnrError && (
            <Alert severity="error" style={{ marginTop: '20px' }}>
              {addPnrError}
            </Alert>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PnrLookup;