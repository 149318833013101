// /Users/sam/Desktop/autopilot-admin-app/src/components/HomePage.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import CountUp from 'react-countup';
import { getToken } from '../utils/auth';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StatTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

export default function HomePage() {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      const token = getToken();
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/get_statistics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
      });
      if (response.status === 403) {
        console.error('Unauthorized access');
        // You might want to redirect to login page or show an error message
        setLoading(false);
        return;
      }
      const data = await response.json();
      setStatistics(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching statistics:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom component="div" sx={{ mb: 4, fontWeight: 'bold' }}>
        Autopilot Beluga Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Item elevation={3}>
            <StatTitle variant="h6">Total Users</StatTitle>
            <StatValue variant="h4">
              <CountUp 
                end={statistics.number_of_users} 
                duration={3} 
                separator="," 
                easingFn={easeInOutCubic}
              />
            </StatValue>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item elevation={3}>
            <StatTitle variant="h6">Total Amount Saved</StatTitle>
            <StatValue variant="h4">
              $<CountUp 
                end={statistics.total_amount_saved} 
                duration={3} 
                separator="," 
                decimals={2} 
                easingFn={easeInOutCubic}
              />
            </StatValue>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item elevation={3}>
            <StatTitle variant="h6">Total Saved (Vouchers)</StatTitle>
            <StatValue variant="h4">
              $<CountUp 
                end={statistics.total_saved_credits} 
                duration={3} 
                separator="," 
                decimals={2} 
                easingFn={easeInOutCubic}
              />
            </StatValue>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item elevation={3}>
            <StatTitle variant="h6">Total Reservations</StatTitle>
            <StatValue variant="h4">
              <CountUp 
                end={statistics.total_number_of_reservations} 
                duration={3} 
                separator="," 
                easingFn={easeInOutCubic}
              />
            </StatValue>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}