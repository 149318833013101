// /Users/sam/Desktop/autopilot-admin-app/src/hooks/useTokenVerification.js

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useTokenVerification = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
      
      if (!token) {
        setIsVerified(false);
        setIsLoading(false);
        navigate('/login');
        return;
      }

      try {
        const response = await fetch('https://dev-api.withautopilot.com/admin_portal/verify_token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token': token
          }
        });

        const data = await response.json();

        if (response.ok && data.success === true) {
          setIsVerified(true);
        } else {
          document.cookie = 'token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          setIsVerified(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        setIsVerified(false);
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, [navigate]);

  return { isVerified, isLoading };
};

export default useTokenVerification;