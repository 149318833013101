// /Users/sam/Desktop/autopilot-admin-app/src/components/CreditInfoLookup.js

import React, { useState } from 'react';
import { Box, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, Grid, CircularProgress, Card, CardContent } from '@mui/material';
import { format } from 'date-fns';

const CreditInfoLookup = () => {
  const [airline, setAirline] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [creditNumber, setCreditNumber] = useState('');
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`https://dev-api.withautopilot.com/dev/find_credit_info?airline=${airline}&credit_number=${creditNumber}&first_name=${firstName}&last_name=${lastName}`, {
        method: 'POST',
        headers: {
          'Key': 'ZSGSPH',
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setResult(Array.isArray(data) ? data : [data]);
    } catch (error) {
      console.error('Error:', error);
      setResult([{ error: 'An error occurred while fetching data.' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy');
  };

  const CreditCard = ({ credit }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>Credit Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography><strong>Credit Number:</strong> {credit.credit_number}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography><strong>Traveler Name:</strong> {credit.traveler_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography><strong>Total Amount:</strong> ${Number(credit.total_amount).toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography><strong>Issue Date:</strong> {formatDate(credit.issue_date)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography><strong>Expiration Date:</strong> {formatDate(credit.expiration_date)}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Lookup Credit Info with Airline</Typography>
      <form onSubmit={handleSearch}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="airline-select-label">Airline</InputLabel>
              <Select
                labelId="airline-select-label"
                value={airline}
                label="Airline"
                onChange={(e) => setAirline(e.target.value)}
                required
              >
                <MenuItem value="aa">American</MenuItem>
                <MenuItem value="dl">Delta</MenuItem>
                <MenuItem value="ua">United</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Credit Number"
              value={creditNumber}
              onChange={(e) => setCreditNumber(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button 
              type="submit"
              variant="contained" 
              color="primary" 
              disabled={isLoading}
              fullWidth
            >
              {isLoading ? <CircularProgress size={24} /> : 'Search'}
            </Button>
          </Grid>
        </Grid>
      </form>
      {result && (
        <Box sx={{ mt: 2, maxWidth: { lg: '50%' }, mx: 'auto' }}>
          {result.map((credit, index) => 
            credit.error ? (
              <Typography key={index} color="error">{credit.error}</Typography>
            ) : (
              <CreditCard key={index} credit={credit} />
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default CreditInfoLookup;