import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import FlightIcon from '@mui/icons-material/Flight';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import { getToken } from '../utils/auth';

const fieldOrder = [
  'user_id',
  'first_name',
  'last_name',
  'email',
  'plan',
  'permissions',
  'referrer',
  'stripe_customer_id',
  'created_at',
  'signin_method',
  'linked_accounts'
];

function UserTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchCriteria, setSearchCriteria] = useState({
    user_id: '',
    first_name: '',
    last_name: '',
    email: '',
    first_last_name: '',
    created_at_date: ''
  });
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(async (e, userIdOverride = null) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    setError(null);

    const payload = Object.entries(searchCriteria).reduce((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {});

    if (userIdOverride) {
      payload.user_id = userIdOverride;
    }

    if (Object.values(payload).every(x => x === '')) {
      setError("Please enter at least one search criterion");
      setIsLoading(false);
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://dev-api.withautopilot.com/admin_portal/get_users", requestOptions);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      setResults(Array.isArray(result) ? result : [result]);
    } catch (e) {
      console.error("There was a problem with the fetch operation:", e);
      setError("An error occurred while fetching the data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [searchCriteria]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userIdFromUrl = params.get('user_id');
    if (userIdFromUrl) {
      setSearchCriteria(prev => ({ ...prev, user_id: userIdFromUrl }));
      handleSubmit(null, userIdFromUrl);
      // Remove the query parameter from the URL
      navigate('/users', { replace: true });
    }
  }, [location, navigate, handleSubmit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleReservationLookup = (userId) => {
    navigate(`/reservation?user_id=${userId}`);
  };

  const handlePassengerLookup = (userId) => {
    navigate(`/passenger?user_id=${userId}`);
  };

  const renderOrderedRow = (row) => {
    return [
      ...fieldOrder.map((field) => {
        if (field === 'signin_method') {
          return <TableCell key={field}>{row.sso_enabled ? 'SSO' : 'UN/PW'}</TableCell>;
        } else if (field === 'linked_accounts') {
          return (
            <TableCell key={field}>
              <Tooltip title={`Linked Gmail Accounts:\n${row.primary_emails.join('\n')}`} arrow>
                <span>{row.primary_emails.length}</span>
              </Tooltip>
            </TableCell>
          );
        } else if (field === 'referrer') {
          return (
            <TableCell key={field}>
              {row.referral_info ? (
                <Tooltip 
                  title={
                    <Box sx={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
                      {`Referred By: ${row.referral_info.referrer_first_name} ${row.referral_info.referrer_last_name} (User ID: ${row.referral_info.referrer_user_id})
Referred On: ${row.referral_info.referral_date}
---------
${row.first_name}'s Referral Code: ${row.referral_info.user_referral_code.toUpperCase()}`}
                    </Box>
                  } 
                  arrow
                  placement="top"
                >
                  <span>{row.referral_info.referrer_referral_code}</span>
                </Tooltip>
              ) : (
                '-'
              )}
            </TableCell>
          );
        } else {
          return <TableCell key={field}>{row[field] || ''}</TableCell>;
        }
      }),
      <TableCell key="actions">
        <IconButton onClick={() => handleReservationLookup(row.user_id)} aria-label="Reservation lookup">
          <FlightIcon />
        </IconButton>
        <IconButton onClick={() => handlePassengerLookup(row.user_id)} aria-label="Passenger lookup">
          <PersonIcon />
        </IconButton>
      </TableCell>
    ];
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginBottom: 2 }}>
          {Object.keys(searchCriteria).map((key) => (
            <TextField
              key={key}
              label={key.replace(/_/g, ' ')}
              name={key}
              value={searchCriteria[key]}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          ))}
        </Box>
        <Button type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Search"}
        </Button>
      </form>

      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

      {results.length > 0 && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="user results table">
            <TableHead>
              <TableRow>
                {fieldOrder.map((field) => (
                  <TableCell key={field}>{field.replace(/_/g, ' ')}</TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((row, index) => (
                <TableRow key={index}>
                  {renderOrderedRow(row)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default UserTable;